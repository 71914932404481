main.login-page {
    display: grid;
    place-items: center;
    height: 100vh;
}

div.login-container {
    border-radius: 8px;
    overflow: hidden;
    min-width: 450px;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

div.login-container div.top-row {
    height: 120px;
    background-color: #d7dbf9;
    color: #608aee;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

div.login-container div.top-row div.text {
    height: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    padding: 24px;
}

div.login-container div.top-row p,
div.login-container div.top-row h5 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 8px;
}

div.login-container div.top-row img {
    max-height: 100px;
}

form.login-form {
    padding: 24px;
}