div.chart-card {
    background-color: var(--cl-hex-bc-card);
    min-height: 30px;
    border: 2px solid var(--cl-hex-border);
    border-radius: 8px;
    padding: 24px 24px;
}

div.main {
    flex: 1;
    display: flex;
    justify-content: center;
}

div.childrens {
    display: flex;
    justify-content: center;
}

div.child {
    flex: 1;
}

div.main div.main-text {
    display: grid;
    place-items: center;

    min-width: 100px;
    padding: 12px 18px;
    border-radius: 6px;
    border: 1.5px solid rgb(107, 107, 107);
}

div.main-text h4 {
    font-size: 17px;
}

div.main-text p {
    font-weight: 400;
    font-size: 14px;
    margin: 8px 0 0 0;
}

button.chart-arrow {
    cursor: pointer;
    width: 100%;
    background-color: #4680ff;
    color: #fafafa;
    outline: none;
    border: none;
    border-radius: 3px;
    display: grid;
    place-items: center;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin-top: 12px;
}

div.chart-line-cont {
    display: grid;
    place-items: center;
}

div.chart-vr-line {
    width: 3px;
    height: 30px;
    background-color: #4680ff;
}

div.chart-hr-line {
    height: 3px;
    background-color: #4680ff;
}