ol.list {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

ol.list li {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 8px;
}

ol.list li a,
ol.list li h5 {
    font-size: 14px;
}

div.article-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

ul.create-menu {
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 12px 12px 8px 12px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 8px 8px 0 0;
}

div.flex-column {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

form.search {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

form.search input {
    min-width: 250px;
}