.chart-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.chart-item p {
    margin: 0;
}

.chart-line {
    display: flex;
    align-items: center;
    justify-content: center;
}

.chart-childrens {
    display: flex;
    justify-content: space-between;
}

.chart-childrens > * {
    flex: 1;
}

.vr-line {
    width: 3px;
    height: 20px;
    background-color: var(--cl-border);
}

.hr-line {
    height: 3px;
    background-color: var(--cl-border);
}

/* .chart-item-primary {
    background-color: rgb(var(--cl-primary-rgb));
    margin-bottom: 10px;
}

.chart-item-blue {
    background-color: rgb(var(--cl-primary-rgb));
    margin-bottom: 10px;
}

.chart-item-green {
    background-color: rgb(var(--cl-success-rgb));
    margin-bottom: 10px;
}

.chart-item-yellow {
    background-color: rgb(var(--cl-warning-rgb));
    margin-bottom: 10px;
} */