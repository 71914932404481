/* FROM */
form {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

form > h3 {
    text-align: center;
}

/* LABELS */

label {
    display: flex;
    flex-direction: column;
    width: 100%;
}

label span {
    font-size: 0.95rem;
    line-height: 1.4rem;
    vertical-align: top;
    margin-bottom: 0.3rem;
}

/* INPUTS */
input[type="text"],
label input[type="date"],
label input[type="email"],
label input[type="password"] {
    width: calc(100% - 1.5rem - 2px);
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: #343a40;
    display: block;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.47rem 0.75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    outline: none;
}

/* label textarea {
    border: 2px solid #575757;
    font-weight: 300;
    letter-spacing: 0.075rem;
    font-size: 0.875rem;
    min-height: 2.575rem;
    padding: 0.8rem;
    border-radius: 0.2rem;
    line-height: 1.5;
    display: block;
    resize: vertical;
    min-height: 260px;
    color: #e9e6e6;
    background-color: #2b2e4c;
    background-clip: padding-box;
    outline: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} */

label textarea {
    width: calc(100% - 1.5rem - 2px);
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: #343a40;
    display: block;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.47rem 0.75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    outline: none;
    resize: none;
    height: 250px;
}

/* label select {
    border: 2px solid #575757;
    font-weight: 300;
    letter-spacing: 0.075rem;
    font-size: 0.875rem;
    min-height: 2.575rem;
    padding: 0.65rem 0.8rem;
    border-radius: 0.2rem;
    display: block;
    line-height: 1;
    color: #e9e6e6;
    background-color: #2b2e4c;
    background-clip: padding-box;
    outline: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} */

label select {
    width: 100%;
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: #343a40;
    display: block;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.47rem 0.75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    outline: none;
}

label textarea:focus,
label select:focus,
label input:focus {
    border-color: rgba(0, 120, 212);
}

/* CHECKBOX */

.checkbox-wrap input {
    appearance: none;
    background-color: #dfe1e4;
    border-radius: 72px;
    border-style: none;
    flex-shrink: 0;
    height: 20px;
    margin: 0;
    position: relative;
    width: 30px;
}

.checkbox-wrap input::before {
    bottom: -6px;
    content: "";
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
}

.checkbox-wrap input,
.checkbox-wrap input::after {
    transition: all 100ms ease-out;
}

.checkbox-wrap input::after {
    background-color: #fff;
    border-radius: 50%;
    content: "";
    height: 14px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 14px;
}

.checkbox-wrap input[type="checkbox"] {
    cursor: default;
}

.checkbox-wrap input:hover {
    background-color: #c9cbcd;
    transition-duration: 0s;
}

.checkbox-wrap input:checked {
    background-color: rgba(0, 120, 212, 1);
}

.checkbox-wrap input:checked::after {
    background-color: #fff;
    left: 13px;
}

.checkbox-wrap :focus:not(.focus-visible) {
    outline: 0;
}

/* BUTTONS */

a {
    text-decoration: none;
    cursor: pointer;
    color: #495057;
    transition: 0.3s;
}

a:hover {
    color: rgba(0, 120, 212, 1);
}

.btn,
button {
    outline: none;
    border: none;
    cursor: pointer;
    transition: 0.3s;
}

.btn {
    padding: 8px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    border: 1px solid transparent;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
}

.btn:hover,
button:hover {
    background-color: rgba(255, 255, 255, 0.08);
}

.btn:disabled,
button:disabled {
    background-color: rgb(56, 63, 63) !important;
}

button.btn-primary,
a.btn-primary {
    color: white;
    text-decoration: none;
    background-color: rgba(0, 120, 212, 1);
    margin: 2px;
    align-items: center;
    border-radius: 0.25rem;
    border: 1px solid transparent;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    flex-shrink: 0;
    font-family: inherit;
    font-size: inherit;
    font-weight: 600;
    justify-content: center;
    line-height: inherit;
    outline: none;
    overflow: visible;
    padding: 6px 12px;
    font-size: 14px;
}

button.btn-primary:hover,
a.btn-primary:hover {
    color: #fff;
    background-color: rgb(0, 103, 181, 0.9);
}

button.btn-error,
a.btn-error {
    color: white;
    text-decoration: none;
    background-color: rgb(212, 39, 0);
    margin: 2px;
    align-items: center;
    border-radius: 0.25rem;
    border: 1px solid transparent;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    flex-shrink: 0;
    font-family: inherit;
    font-size: inherit;
    font-weight: 600;
    justify-content: center;
    line-height: inherit;
    outline: none;
    overflow: visible;
    padding: 6px 12px;
    font-size: 14px;
}

button.btn-error:hover,
a.btn-error:hover {
    color: #fff;
    background-color: rgba(181, 6, 0, 0.9);
}

/* BADGES */

label.badge {
    border-radius: 20px;
    font-size: 12px;
    line-height: 1;
    padding: 0.375rem 0.5625rem;
    font-weight: normal;
    width: max-content;
}

label.badge-primary {
    color: rgb(var(--cl-primary-rgb));
    border: 1px solid rgb(var(--cl-primary-rgb));
}

label.badge-success {
    color: rgb(var(--cl-success-rgb));
    border: 1px solid rgb(var(--cl-success-rgb));
}

label.badge-danger {
    color: rgb(var(--cl-danger-rgb));
    border: 1px solid rgb(var(--cl-danger-rgb));
}

label.badge-warning {
    color: rgb(var(--cl-warning-rgb));
    border: 1px solid rgb(var(--cl-warning-rgb));
}

label.badge-info {
    color: rgb(var(--cl-info-rgb));
    border: 1px solid rgb(var(--cl-info-rgb));
}

.sl-team {
    width: max-content;
}

.sl-team select {
    width: 100px;
}