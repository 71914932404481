@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --cl-primary-rgb: 13, 110, 253;
  --cl-success-rgb: 25, 135, 84;
  --cl-danger-rgb: 220, 53, 69;
  --cl-warning-rgb: 255, 193, 7;
  --cl-info-rgb: 13, 202, 240;
  /* --cl-border: #a3a4a5; */
  --cl-border: rgba(255, 255, 255, 0.18);
  --cl-theme: #4B49AC;
}

[data-theme="dark"] {
  /* --cl-darker: #151419; */
  --cl-darker: rgba(37,36,35,255);
  /* --cl-dark: #232227; */
  --cl-dark: rgba(32, 31, 30, 255);
  --cl-text: #d0cfcf;
}

* {
  /* font-family: 'Nunito', sans-serif; */
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #f6f6fa;
  font-family: 'Nunito', sans-serif;
  color: #495057;
}

ul, li {
  list-style: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  line-height: 1;
}

h1 {
  font-size: 3.125rem;
}

h2 {
  font-size: 2.187rem;
}

h3 {
  font-size: 1.525rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.9375rem;
}

p {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  line-height: 1.3rem;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--cl-dark);
}

::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 4px;
}