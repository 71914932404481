aside {
    position: sticky;
    top: 0px;
    left: 0;
    bottom: 0;
    z-index: 100;
    width: 250px;
    min-width: 200px;
    height: 100vh;
    background-color: #2B3042;
    transition-duration: 0.3s;
    color: white;
}

aside div.logo {
    height: 70px;
    display: grid;
    place-items: center;
    border-bottom: 1px solid var(--cl-border);
}

aside div.logo h3 {
    font-size: 28px;
    font-weight: 900;
}

aside nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0;
}

aside nav ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

aside nav ul li a {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    padding: 13px 20px;
    transition-duration: 0.3s;
    font-size: 13px;
    line-height: 1;
    font-weight: 500;
    background-color: transparent;
    color: #878da9;
}

aside nav ul li a svg {
    color: white;
}

aside nav ul li a:hover {
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
}

aside nav ul li a:hover svg {
    color: white;
}

aside nav ul li a span {
    margin-top: 2px;
}

aside nav ul li button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 7px;
}