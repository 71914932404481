main.not-found {
    display: grid;
    place-items: center;
    min-height: 100vh;
    text-align: center;
}

main.not-found div {
    padding: 28px 70px;
    border-radius: 14px;
}

main.not-found div h6 {
    margin-bottom: 20px;
}