header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 70px;
    background-color: white;
    border-bottom: 1px solid #878da9;
}

header div.header-btns {
    display: flex;
    align-items: center;
    gap: 4px;
}

header button.profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background-color: transparent;
    margin-left: 8px;
}

header button.profile img {
    width: 34px;
    height: 34px;
    border-radius: 4px;
    border: 2px solid #495057;
}

header a:hover {
    background-color: rgba(0, 0, 0, 0.06) !important;
}