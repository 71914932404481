main.body {
    display: flex;
    min-height: 100vh;
}

main.body > div.content {
    flex: auto;
}

section {
    min-height: calc(100vh - 70px - 50px - 49px);
    padding: 24px 32px;
}