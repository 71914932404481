div.card-n {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    background-color: white;
}

input.inp-n {
    max-width: 250px;
}

div.table-container {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: white;
    min-width: 250px;
    border-radius: 6px;
    overflow: hidden;
}

table {
    width: 100%;
    border-collapse: collapse;
}

table thead tr th {
    font-weight: 600;
    font-size: 1rem;
    white-space: nowrap;
    text-align: left;
    padding: 0.75rem;
    font-size: 13px;
    background-color: #eff2f7;
    color: #000;
}

table tbody tr td:first-child,
table thead tr th:first-child {
    padding-left: 1.75rem;
}

table tbody tr:hover {
    background-color: #f8f9fa;
}

table tbody tr td {
    padding: 0.75rem;
    white-space: nowrap;
    color: #495057;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
}

table tbody tr td:last-child,
table thead tr th:last-child {
    text-align: center;
}

.center {
    text-align: center;
}