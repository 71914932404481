.modal-cont {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    place-items: center;
    z-index: 200;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    height: calc(100vh - 40px);
    width: calc(100vw - 40px);
}

.modal-cont > button {
    cursor: default;
    background-color: transparent;
}

.modal-cont > div {
    min-width: 270px;
}

.modal-cont > div > form {
    margin-top: 12px;
}

.modal-cont .card {
    min-width: 450px;
}