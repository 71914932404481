.flex-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
}

.flex-body div:nth-child(1) {
    flex: 3;
}

.flex-body div:nth-child(2) {
    flex: 1;
}

.flex-body-reverse {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
}

.flex-body-reverse div:nth-child(1) {
    flex: 2;
}

.flex-body-reverse div:nth-child(2) {
    flex: 1;
}

/* OTHER */

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    gap: 0.9rem;
    height: max-content;
}

.flex-end-btm {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 0.9rem;
}

.flex-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}

.flex-row > * {
    flex: 1;
}

div.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

/* CARDS */

.card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: white;
    padding: 20px;
    border-radius: 6px;
    overflow: hidden;
}

/* MARGINS, PADDINGS */

.m-t {
    margin-top: 24px;
}

.p-b {
    padding-bottom: 12px;
}

.b-b {
    border-bottom: 1px solid var(--cl-border);
}

.m-b {
    margin-bottom: 24px;
}

.m-n {
    margin: 0 !important;
}

/* TEXT */

.text-center {
    text-align: center;
}

.text-end {
    text-align: right;
}

/* BTN GROUP */

.btn-group {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.btn-group > button {
    flex: 1;
}

/* COLORS */

.error {
    color: rgb(var(--cl-danger-rgb));
}

.warn {
    color: rgb(var(--cl-warning-rgb));
}

.success {
    color: rgb(var(--cl-success-rgb));
}

div.page-title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}

div.page-title h4 {
    font-size: 18px;
    font-weight: 600;
}

div.page-title ul.breadcrumb {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 13px;
}

