footer {
    height: 50px;
    background-color: white;
    border-top: 1px solid var(--cl-hex-border);
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

footer h5 {
    font-size: 13px;
}