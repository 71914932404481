article {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 48px 20px 36px 20px;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* Header */

article div.header {
    width: 100%;
    margin-bottom: 24px;
    max-width: 800px;
    display: grid;
    place-items: center;
}

article div.header h4.title {
    text-align: center;
    margin-bottom: 24px;
}

article div.header div.info {
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 24px;
}

article div.header div.info p {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

article div.hr-line {
    width: 100%;
    height: 2px;
    background-color: #9a9a9a;
}

/* Contect */

article div.content {
    max-width: 800px;
}

article div.content div {
    display: grid;
    place-items: center;
    margin-bottom: 36px;
}

article div.content img {
    padding: 14px;
    border: 1px solid #9a9a9a;
    width: 100%;
    max-width: 600px;
    max-height: 400px;
}

article div.content p {
    line-height: 1.8;
    margin-bottom: 36px;
}

article div.content h5 {
    line-height: 1.8;
    margin-bottom: 12px;
}

article div.content h6 {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 36px;
}

article div.content h6 a {
    color: rgba(0, 120, 212, 1);
}